import React from "react";
import {Button, Col, Container, Form, Modal, Row,} from "@themesberg/react-bootstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectResellerCompanyName} from "../../../store/reducers/userCompany";

const InvoiceLimitModal = ({show, handleClose, data}) => {
  const {t} = useTranslation();

  const closeModal = () => {
    handleClose();
  };

  const resellerCompanyName = useSelector(selectResellerCompanyName);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={closeModal}
        style={{height: "90vh"}}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>{t("Invoice subscription limit reach")}</Modal.Title>
          </Modal.Header>
          <Modal.Body scrollable="true">
            <Container>
              {resellerCompanyName ? (
                <p>
                  {t("You have exceeded the limits of your subscription. Please contact")} <u
                  className="fw-bold">{`${resellerCompanyName}`}</u> {t("to upgrade your subscription")}
                </p>
              ) : (
                <Row>
                  <Col>
                    <p>{`At this moment, you can not create a new invoice because you have created ${data["total"]} of the  ${data["limit"]}  invoices you are allowed to create monthly in your subscription.`}</p>
                    <h6 className="fw-bolder">
                      {t("Please upgrade subscription to be able to create a new invoice")}
                    </h6>
                  </Col>
                </Row>
              )}
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={closeModal}>
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default InvoiceLimitModal;
