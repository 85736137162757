import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Nav, Card, Pagination as BootstrapPagination} from "@themesberg/react-bootstrap";

export function Pagination({links, total, page, perPage, lastPage, setPage, from, to}) {
  const {t} = useTranslation();

  const handlePagination = (direction) => {
    if (direction === 'prev' && page > 0) {
      setPage(page - 1)
    } else if (direction === 'next' && page < lastPage) {
      setPage(page + 1)
    } else {
      setPage(direction)
    }
  }

  return (
    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
      {links &&
        <Nav>
          <BootstrapPagination className="mb-2 mb-lg-0">
            <BootstrapPagination.Prev onClick={() => handlePagination('prev')}>
              {t("Previous")}
            </BootstrapPagination.Prev>
            {
              links.filter((link, index) => (index !== 0 && index !== (links?.length - 1))).map(link => {
                return (
                  <BootstrapPagination.Item active={link.active} onClick={() => handlePagination(link.label)}
                                            key={link.label}>{link.label}</BootstrapPagination.Item>
                )
              })
            }
            <BootstrapPagination.Next onClick={() => handlePagination('next')}>
              {t("Next")}
            </BootstrapPagination.Next>
          </BootstrapPagination>
        </Nav>
      }
      <small className="fw-bold">
        {from === 0 ? (
          <>
            {t('No items to show')}
          </>
        ) : (
          <Trans i18nKey="showing_entries" values={{from, to, total}}>
            Showing <b>{{from}}</b> - <b>{{to}}</b> out of <b>{{total}}</b> entries
          </Trans>
        )}
      </small>
    </Card.Footer>
  )
}
