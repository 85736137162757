import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Modal} from "react-bootstrap";
import Invoice from "../libs/Invoice";
import {startLoading, stopLoading} from '../../store/actions/loading';
import {useDispatch} from "react-redux";
import "../styles.css";
import {useTranslation} from "react-i18next";

function ReminderModal({
                         show,
                         handleClose,
                         invoiceId,
                         email,
                         isFirstReminderDisabled,
                       }) {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [sendInvoiceEmail, setSendInvoiceEmail] = useState(email);
  const [reminder, setReminder] = useState("");
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [textMessage, setTextMessage] = useState("");

  useEffect(() => {
    setSendInvoiceEmail(email);
  }, [email]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(startLoading());
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(stopLoading());
    } else {
      const reminderStatus = isFirstReminderDisabled ? 'second' : 'first';
      Invoice.sendReminders(invoiceId, {"reminder_type": reminderStatus, email: sendInvoiceEmail}).then(res => {
        handleClose(true);
        setShowError(false);
        dispatch(stopLoading());
      }).catch(error => {
        if (error && error.message) {
          setShowError(true)
          setTextMessage(error.message);
        }
        dispatch(stopLoading());
        console.log(error)
      });
      // state clear and close modal
      setValidated(false);
      dispatch(stopLoading());
    }
    dispatch(stopLoading());
    setValidated(true);
  };


  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Reminder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showError && <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
            <p className="m-0">{textMessage}</p>
          </Alert>}
          <div className="container text-black" style={{backgroundSize: "100%", backgroundRepeat: "no-repeat"}}>
            <Form.Group className="col-md-12">
              <Form.Label>{t("Email")} *</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={(e) => setSendInvoiceEmail(e.target.value)}
                value={sendInvoiceEmail}
              />
            </Form.Group>
            <Form.Group className="col-md-12 mt-3">
              <Form.Label>{t("Reminder")}</Form.Label>
              <Form.Select
                onChange={(e) => setReminder(e.target.value)}
                name="reminder"
                value={reminder}
              >
                <option value="first" disabled={isFirstReminderDisabled}>{t("First Reminder")}</option>
                <option value="second" disabled={!isFirstReminderDisabled}>{t("Second Reminder")}</option>
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => handleClose()}>
            {t("Close")}
          </Button>
          <Button variant="primary" type="sumbit">
            {t("Send Reminder")}
          </Button>
        </Modal.Footer>
      </Form>

    </Modal>
  );
}

export default ReminderModal;
