import React from 'react';
import {Button, OverlayTrigger, Popover, Row} from "@themesberg/react-bootstrap";
import {SketchPicker} from 'react-color';
import {useTranslation} from 'react-i18next';

export default function PopOver({color, pickerShow, setPickerShow, handleColorChange}) {
  const {t} = useTranslation();

  return (
    <OverlayTrigger
      style={{padding: 0, margin: '0!importance'}}
      show={pickerShow}
      placement="left"
      trigger="click"
      overlay={(
        <Popover style={{padding: 0, margin: '0!importance'}}>
          <Popover.Content>
            <SketchPicker color={color} onChangeComplete={(color) => handleColorChange(color.hex)}/>
            <Row className="p-2">
              <Button variant="primary" size="sm" onClick={() => setPickerShow(false)}>
                {t("Close")}
              </Button>
            </Row>
          </Popover.Content>
        </Popover>
      )}>
      <Button size="sm" variant="info" onClick={() => setPickerShow(!pickerShow)}>
        {t("Select Color")}
      </Button>
    </OverlayTrigger>
  )
}
