import React, {useEffect, useState} from "react";
import {Button, Form, Image, Modal, Table} from "react-bootstrap";
import "../styles.css";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import {numberFormatter} from "../../libs/numberFormatter";
import {useSelector} from "react-redux";
import {selectCompanySettings} from "../../store/reducers/settings";
import {selectActiveCompany} from "../../store/reducers/userCompany";
import {useFormikContext} from "formik";
import Invoice from "../libs/Invoice";
import {selectTemplates} from "../../store/reducers/templates";
import {selectCustomers} from "../../store/reducers/customers";

function InvoiceLayout({
                         show,
                         handleClose,
                         invoiceData,
                         invoiceLines
                       }) {
  const {t} = useTranslation();
  const companySettings = useSelector(selectCompanySettings);
  const company = useSelector(selectActiveCompany);
  const customers = useSelector(selectCustomers);

  const [template, setTemplate] = useState();
  const [customer, setCustomer] = useState();

  const templates = useSelector(selectTemplates);
  const {values, setFieldValue} = useFormikContext();

  const backgroundImg = template?.background_image_url || "";

  useEffect(() => {
    let selectedTemplate = templates?.filter((template) => template?.id === values.template_id)?.[0];

    // if there is no template found for the template id, use default
    if (!selectedTemplate) {
      selectedTemplate = templates?.filter((template) => template?.is_default === true)?.[0];

      if (selectedTemplate?.id) {
        setFieldValue('template_id', selectedTemplate?.id)
      }
    }

    setTemplate(selectedTemplate);
  }, [templates, values.template_id]);

  useEffect(() => {
    let selectedCustomer = customers?.filter((customer) => customer?.id === values.customer_id)?.[0];

    setCustomer(selectedCustomer);
  }, [customers, values.customer_id]);

  const [totalPrices, setTotalPrices] = useState({
    totalPriceExclVat: 0,
    totalVat: 0,
    totalInclVat: 0,
  });

  useEffect(() => {
    setTotalPrices(Invoice.calculateTotalPrices(values.invoice_lines));
  }, [values.invoice_lines]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>{t("Invoice Layout")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          width: '210mfcm',
          height: '297mm',
          fontFamily: '--bs-font-monospace',
          marginTop: template?.margin_top || 10,
          marginBottom: template?.margin_bottom || 10
        }}>
          <div className="container text-black"
               style={{
                 backgroundImage: `url(${backgroundImg})`
                 , backgroundSize: "100%", backgroundRepeat: "no-repeat"
               }}>
            <div className="row">
              <div className="col-12">
                <Form.Group>
                  {companySettings?.logo_url && (
                    <Image
                      src={companySettings?.logo_url}
                      style={{width: "200px", objectFit: "contain"}}
                      alt="logo"
                    />
                  )}
                </Form.Group>
                <h1 style={{fontFamily: '--bs-font-monospace'}} className="mt-4 mb-2">{company?.name}</h1>
                <div style={{fontSize: '14px'}}>
                  {company?.street +
                    " " +
                    company?.house_number +
                    " " +
                    (company?.house_number_suffix ? company?.house_number_suffix : "")
                  }
                  <br/>
                  {company?.postcode +
                    " " +
                    company?.city +
                    " " +
                    company?.country}
                  <br/>
                  {company?.phone_number}
                </div>
                <div><strong>{t("coc number")}:</strong> {company?.coc_number}</div>
                <div><strong>{t("VAT Number")}:</strong> {company?.vat_number}</div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <div className="text-center" style={{fontSize: "18px"}}>
                  <strong> {t("Invoice details")}</strong>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <div>
                  <strong className="text-uppercase">Bill to</strong>
                  <hr style={{margin: "0px 0px 5px 0px", opacity: 0.5}}/>
                </div>
                {customer?.company_name ? (
                  <div style={{fontSize: '14px'}}>
                    <div>{customer.company_name || ''}</div>
                    <div>
                      {customer.street +
                        " " +
                        customer.house_number +
                        " " +
                        (customer?.house_number_suffix ? customer?.house_number_suffix : "")}
                      <br/>
                      {customer.postcode +
                        " " +
                        customer.city +
                        " " +
                        customer.country}
                    </div>
                    <div>{customer?.phone_number || ''}</div>
                  </div>
                ) : '-'}
              </div>
              <div className="col-md-6">
                <div style={{float: "right"}}>
                  <div>
                    <strong>{t("Invoice date")}:</strong>
                    {moment
                      .tz(values.invoice_date, "Europe/Amsterdam")
                      .format("DD-MM-YYYY")}
                  </div>
                  <div><strong>{t("Invoice number")}:</strong> {invoiceData?.invoice_number}</div>
                  <div><strong>{t("Bank Account")}:</strong> {companySettings?.bank_account}</div>
                  <div><strong>{t("Bank Account Holder")}:</strong> {companySettings?.bank_account_holder}</div>

                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">

              </div>
              <div className="col-6 text-right">

              </div>
            </div>
            <div className="d-flex col-12 mt-4">
              <Table size="sm">
                <thead>
                <tr style={{
                  fontSize: " 0.8em",
                  lineHeight: "0.8",
                  backgroundColor: template?.theme_color_primary || '#78ad91',
                  color: "white"
                }}>
                  <th className="text-center">SL#</th>
                  <th className="text-center">{t("Description")}</th>
                  <th className="text-center">{t("Quantity")}</th>
                  <th className="text-center">{t("Unit Price")}</th>
                  <th className="text-center">{t("Vat Percentage")}</th>
                  <th className="text-center">{t("Total")}</th>
                </tr>
                </thead>
                <tbody>
                {invoiceLines &&
                  invoiceLines.map((invoiceLine, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{invoiceLine.description}</td>
                        <td className="text-center">
                          {invoiceLine.quantity}
                        </td>
                        <td className="text-center">
                          {numberFormatter.format(invoiceLine.price / 100)}
                        </td>
                        <td className="text-center">
                          {invoiceLine.vat_percentage}%
                        </td>
                        <td className="text-center">
                          {numberFormatter.format(invoiceLine.amountExclVat_field)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="row mt-4 mb-4 justify-content-end">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-8"><strong>{t("Total excl. VAT")}: </strong></div>
                  <div className="col-4">
                    <strong>{numberFormatter.format(totalPrices.totalPriceExclVat)}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8"><strong>{t("Total VAT")}: </strong></div>
                  <div className="col-4">
                    <strong>{numberFormatter.format(totalPrices.totalVat)}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <strong>{t("Total incl. VAT")}:</strong>
                  </div>
                  <div className="col-4">
                    <strong> {numberFormatter.format(totalPrices.totalInclVat)}</strong>
                  </div>
                </div>
              </div>
            </div>
            {/* <hr />
            <div className="row mt-2">
              <div className="col-6">
                <div>
                  <strong>Contact information:</strong>
                </div>
                <br />
                <div>Tel: {company.phone_number}</div>
                <div>E-mail: {company.email}</div>
                <div>Website: {company.website_url}</div>
              </div>
              <div className="col-6 pl-160 ">
                <div>
                  <strong>Payment information:</strong>
                </div>
                <br />
                <div>COC: {company.coc_number}</div>
                <div>Vat Number: {company.vat_number}</div>
              </div>
            </div> */}
          </div>
          <div style={{
            width: "100%",
            left: "0px",
            bottom: "10px",
            textAlign: "center",
            marginTop: "30px",
            position: "absolute",
            height: "30px"
          }}>
            {template?.closing_text !== 'null' ? template?.closing_text : ''}
          </div>
        </Modal.Body>
      </Form>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleClose()}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InvoiceLayout;
