import React from 'react'
import {useTranslation} from 'react-i18next'
import {Nav, Card, Pagination} from "@themesberg/react-bootstrap";

export function OldPagination({paginationData, items, getPaginated, action}) {
  console.log({paginationData});

  const {t} = useTranslation();
  const linksLength = paginationData.links.length;

  const handlePagination = (page) => {
    if (page === 'prev') {
      if (paginationData.prevPageUrl) {
        let pageNumber = action?.getPrevNextPageNumber(paginationData.prevPageUrl);
        getPaginated(pageNumber);
      }
    } else if (page === 'next') {
      if (paginationData.nextPageUrl) {
        let pageNumber = action?.getPrevNextPageNumber(paginationData.nextPageUrl);
        getPaginated(pageNumber);
      }
    } else {
      getPaginated(page);
    }
  }

  return (
    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
      {paginationData?.links?.length > 0 &&
        <Nav>
          <Pagination className="mb-2 mb-lg-0">
            <Pagination.Prev onClick={() => handlePagination('prev')}>
              {t("Previous")}
            </Pagination.Prev>
            {
              paginationData.links.filter((link, index) => (index !== 0 && index !== (linksLength - 1))).map(link => {
                return (
                  <Pagination.Item active={link.active} onClick={() => handlePagination(link.label)}
                                   key={link.label}>{link.label}</Pagination.Item>
                )
              })
            }
            <Pagination.Next onClick={() => handlePagination('next')}>
              {t("Next")}
            </Pagination.Next>
          </Pagination>
        </Nav>
      }
      <small className="fw-bold">
        {t("Showing")} <b>{items?.length === 0 ? 0 : paginationData.total < paginationData?.perPage ? paginationData?.total : paginationData?.perPage }</b> {t("out of")}{' '}
        <b>{paginationData?.total}</b> {t("entries")}
      </small>
    </Card.Footer>
  )
}
